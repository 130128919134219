import React, { Component } from "react";
import {
  FaGithub,
  FaTwitter,
  FaLinkedin,
  FaEnvelope,
  FaMedium,
} from "react-icons/fa";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>
            Arto Liukkonen
            <br />
            <span className="punchline">Developing all things serverless!</span>
          </p>
          <p>
            <a
              className="App-link"
              href="https://github.com/artoliukkonen"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
            </a>

            <a
              className="App-link"
              href="https://medium.com/@artoliukkonen"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaMedium />
            </a>

            <a
              className="App-link"
              href="https://twitter.com/artoliukkonen"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>

            <a
              className="App-link"
              href="https://linkedin.com/in/artoliukkonen"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>

            <a
              className="App-link"
              href="mailto:arto@51.fi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaEnvelope />
            </a>
          </p>
        </header>
      </div>
    );
  }
}

export default App;
